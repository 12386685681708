import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { AuthenticationService } from '../authentication/authentication.service';
import { Observable } from 'rxjs';
import { ApproveService } from 'src/app/modules/approval/approve.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class EmailGuard implements CanActivate, CanActivateChild {
  constructor(
    private approveService: ApproveService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | boolean {
    const isNonUser = this.checkIsNonUser(state.url);
    const currentUser = this.authenticationService.currentUserValue;
    if (
      (currentUser && localStorage.getItem('emailIsLoggedIn')) ||
      isNonUser
    ) {
      localStorage.removeItem('emailIsLoggedIn');
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  // tslint:disable-next-line:max-line-length
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  checkIsNonUser(url: string) {
    const token = url.split('/').pop().split('?')[0];
    const payload = JSON.parse(atob(token));
    const jwt_key = payload.email_auth_key;
    const header = new HttpHeaders().set('Authorization', jwt_key);
    const is_non_user = this.approveService
      .getUserEmailDetail(header)
      .subscribe((user: any) => {
        return user.is_custom;
      });
    return is_non_user;
  }
}
